import request from "@/utils/request";

// 物料分类
export function goodsClassificationList(params) {
  return request({ url: `/goods_categories/`, method: "get", params });
}

export function goodsClassificationCreate(data) {
  return request({ url: `/goods_categories/`, method: "post", data });
}

export function goodsClassificationUpdate(data) {
  return request({ url: `/goods_categories/${data.id}/`, method: "put", data });
}

export function goodsClassificationDestroy(data) {
  return request({ url: `/goods_categories/${data.id}/`, method: "delete", data });
}

// 物料信息
export function goodsInformationList(params) {
  return request({ url: `/goods/`, method: "get", params });
}

export function goodsRetrieve(params) {
  return request({ url: `/goods/${params.id}/`, method: "get", params });
}

export function goodsInformationCreate(data) {
  return request({ url: `/goods/`, method: "post", data });
}

export function goodsInformationUpdate(data) {
  return request({ url: `/goods/${data.id}/`, method: "put", data });
}

export function goodsInformationDestroy(data) {
  return request({ url: `/goods/${data.id}/`, method: "delete", data });
}

export function goodsBOMList(params) {
  return request({ url: `/goods/${params.id}/bom_list/`, method: "get", params });
}

export function goodsBOMImport(data) {
  return request({ url: `/goods/import_bom_data/`, method: "post", data });
}

// 物料单位
export function goodsUnitList(params) {
  return request({ url: `/goods_units/`, method: "get", params });
}

export function goodsUnitCreate(data) {
  return request({ url: `/goods_units/`, method: "post", data });
}

export function goodsUnitUpdate(data) {
  return request({ url: `/goods_units/${data.id}/`, method: "put", data });
}

export function goodsUnitDestroy(data) {
  return request({ url: `/goods_units/${data.id}/`, method: "delete", data });
}

// BOM
export function bomDetailList(params) {
  return request({ url: `/bom_details/`, method: "get", params });
}

export function bomDetailCreate(data) {
  return request({ url: `/bom_details/`, method: "post", data });
}

export function bomDetailUpdate(data) {
  return request({ url: `/bom_details/${data.id}/`, method: "put", data });
}

export function bomDetailDestroy(data) {
  return request({ url: `/bom_details/${data.id}/`, method: "delete", data });
}

export function bomFileUpload(data) {
  return request({
    url: "/bom_files/",
    method: "post",
    data,
  });
}
