import request from '@/utils/request';

// 客户模板
export function clientTemplate(params) {
  return request({
    url: "/clients/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 客户导入
export function clientImport(data) {
  return request({
    url: "/clients/import_data/",
    method: "post",
    data,
  });
}

// 供应商模板
export function supplierTemplate(params) {
  return request({
    url: "/suppliers/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 供应商导入
export function supplierImport(data) {
  return request({
    url: "/suppliers/import_data/",
    method: "post",
    data,
  });
}

// 仓库模板
export function warehouseTemplate(params) {
  return request({
    url: "/warehouses/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 仓库导入
export function warehouseImport(data) {
  return request({
    url: "/warehouses/import_data/",
    method: "post",
    data,
  });
}

// 结算账户模板
export function settlementAccountTemplate(params) {
  return request({
    url: "/accounts/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 结算账户导入
export function settlementAccountImport(data) {
  return request({
    url: "/accounts/import_data/",
    method: "post",
    data,
  });
}

// 收支项目模板
export function revenueExpenditureItemsTemplate(params) {
  return request({
    url: "/charge_items/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 收支项目导入
export function revenueExpenditureItemsImport(data) {
  return request({
    url: "/charge_items/import_data/",
    method: "post",
    data,
  });
}

// 物料分类模板
export function goodsClassificationTemplate(params) {
  return request({
    url: "/goods_categories/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 物料分类导入
export function goodsClassificationImport(data) {
  return request({
    url: "/goods_categories/import_data/",
    method: "post",
    data,
  });
}

// 物料信息模板
export function goodsInformationTemplate(params) {
  return request({
    url: "/goods/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 物料信息导入
export function goodsInformationImport(data) {
  return request({
    url: "/goods/import_data/",
    method: "post",
    data,
  });
}

// 物料单位模板
export function goodsUnitTemplate(params) {
  return request({
    url: "/goods_units/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 物料单位导入
export function goodsUnitImport(data) {
  return request({
    url: "/goods_units/import_data/",
    method: "post",
    data,
  });
}

// // 原料字典模板
// export function materialDictTemplate(params) {
//   return axios({
//     url: '/api/materials/import_template/',
//     headers: { 'X-CSRFToken': Cookies.get('csrftoken'), Authorization: 'Bearer ' + Cookies.get('access') },
//     responseType: 'blob',
//     method: 'get',
//     params,
//   })
// }

// // 原料字典导入
// export function materialDictImport(data) {
//   return axios({
//     url: '/api/materials/import_data/',
//     headers: { 'X-CSRFToken': Cookies.get('csrftoken'), Authorization: 'Bearer ' + Cookies.get('access') },
//     method: 'post',
//     data,
//   })
// }

// // 成品字典模板
// export function productsDictTemplate(params) {
//   return axios({
//     url: '/api/products/import_template/',
//     headers: { 'X-CSRFToken': Cookies.get('csrftoken'), Authorization: 'Bearer ' + Cookies.get('access') },
//     responseType: 'blob',
//     method: 'get',
//     params,
//   })
// }

// // 成品字典导入
// export function productsDictImport(data) {
//   return axios({
//     url: '/api/products/import_data/',
//     headers: { 'X-CSRFToken': Cookies.get('csrftoken'), Authorization: 'Bearer ' + Cookies.get('access') },
//     method: 'post',
//     data,
//   })
// }

// BOM导入
export function bomDetailTemplate(params) {
  return request({
    url: "/bom_details/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function bomDetailImport(data) {
  return request({
    url: "/bom_details/import_data/",
    method: "post",
    data,
  });
}
