import request from '@/utils/request';

// 客户
export function clientExport(params) {
  return request({
    url: "/clients/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 供应商
export function supplierExport(params) {
  return request({
    url: "/suppliers/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 仓库
export function warehouseExport(params) {
  return request({
    url: "/warehouses/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 结算账户
export function settlementAccountExport(params) {
  return request({
    url: "/accounts/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 收支项目
export function revenueExpenditureItemsExport(params) {
  return request({
    url: "/charge_items/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 物料分类
export function goodsClassificationExport(params) {
  return request({
    url: "/goods_categories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 物料信息
export function goodsInformationExport(params) {
  return request({
    url: "/goods/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 物料单位
export function goodsUnitExport(params) {
  return request({
    url: "/goods_units/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 销售报表导出
export function salesReportExport(params) {
  return request({
    url: "/sales_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 采购报表导出
export function purchaseReportExport(params) {
  return request({
    url: "/purchase_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 库存报表导出
export function inventoryExport(params) {
  return request({
    url: "/inventories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function goodsInventoryExport(params) {
  return request({
    url: "/goods_inventories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function batchExport(params) {
  return request({
    url: "/batchs/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 库存报表导出
export function bomDetailExport(params) {
  return request({
    url: "/bom_details/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}
